<mc-button
  [label]="label()"
  [icon]="icon()"
  [fit]="fit()"
  [appearance]="appearance()"
  [variant]="variant()"
  [hiddenlabel]="hiddenlabel()"
  (click)="openModal()">
</mc-button>
<mc-modal
   fit="medium"
   dimension="small"
   width="600px"
   (closed)="close()"
   [open]="showModal()"
  [heading]="modalHeading()">
  <p>{{modalBody()}}</p>
  <mc-button slot="primaryAction" appearance="primary" (click)="confirm()">{{confirmButtonLabel()}}</mc-button>
  <mc-button slot="secondaryAction" (click)="cancel()" appearance="neutral" variant="outlined">{{cancelButtonLabel()}}</mc-button>
</mc-modal>